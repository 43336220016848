import { EditIcon, PhoneIcon } from "@chakra-ui/icons";
import { Box, Button, Divider, Flex } from "@chakra-ui/react";
import { ChronoUnit, LocalDateTime } from "@js-joda/core";
import { fromPlaceId, toMfAddressComponents } from "mf-google-geocoder";
import React from "react";
import { BodyOf, Messages, ResponseOf } from "../../../core/api";
import { EntityCardLink } from "../../../shared/components/EntityCard";
import { GOOGLE_MAPS_API_KEY } from "../../../shared/consts";
import useGoogleMapsAddress from "../../../shared/hooks/useGoogleMapsAddress";
import SaveIcon from "../../../shared/icons/SaveIcon";
import { NoteId, PatientId } from "../../../shared/schema/schema";
import IntakePatientProfileNotesSection from "./IntakePatientProfileNotesSection";
import IntakeProfileContactInfoSection from "./IntakeProfileContactInfoSection";
import IntakeProfileMedInfoSection from "./IntakeProfileMedInfoSection";
import IntakeProfileNYIAInfo from "./IntakeProfileNYIAInfo";
import IntakeProfilePersonalInfoSection from "./IntakeProfilePersonalInfoSection";
import IntakeProfilePlanInfo, { IntakePlan } from "./IntakeProfilePlanInfo";

interface Props {
  intakePlans: IntakePlan[];
  patientInfo: Messages["IntakePatientProfile"];
  intakeStatuses: ResponseOf<"get", "./patient_intake/intake_status">["intakeStatuses"];
  eligibilityCheckResults: ResponseOf<"get", "./eligibility_checks">["records"];
  onClickPatientLink: () => void;
  onClickMarkNoteAsDone: (noteId: NoteId) => void;
  onClickAddNewNote: (patientId: PatientId) => void;
  onClickEligibilityCheck: (pateintId: PatientId) => void;
  onEditPatientIntakeProfile: (details: BodyOf<"put", "./patients/:patientId/intake">) => void;
}

const IntakePatientProfile = (props: Props) => {
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const [intakeProfile, setIntakeProfile] = React.useState<Messages["IntakePatientProfile"]>(
    props.patientInfo
  );

  const handleEmergencyContactChange = (
    field: "firstName" | "lastName" | "mobilePhoneNumber" | "homePhoneNumber" | "relationship",
    value: string
  ) => {
    const prevFamilyInformation = intakeProfile.familyInformation;
    if (prevFamilyInformation === null || prevFamilyInformation.at(0) === undefined) {
      const emptyContact = {
        firstName: "",
        lastName: "",
        homePhoneNumber: "",
        mobilePhoneNumber: "",
        relationship: "",
      };

      setIntakeProfile({
        ...intakeProfile,
        familyInformation: [{ ...emptyContact, [field]: value }],
      });
    } else {
      const newFamilyInformation = {
        ...prevFamilyInformation[0],
        [field]: value,
      };

      const newFamilyContactsArray = intakeProfile.familyInformation;
      prevFamilyInformation[0] = newFamilyInformation;
      setIntakeProfile({ ...intakeProfile, familyInformation: newFamilyContactsArray });
    }
  };

  const handleEditModeChange = (newValue: boolean) => {
    setIsEditMode(newValue);
    if (!newValue) {
      props.onEditPatientIntakeProfile(intakeProfile);
    }
  };

  const {
    handleChange: handleChangeAddress,
    placePredictions,
    getDetails: getAddressDetails,
    showPredictions,
    setShowPredictions,
  } = useGoogleMapsAddress();

  const handleClickAddress = (prediction: google.maps.places.AutocompletePrediction) => {
    handleClickAddressPrediction(prediction).then(({ address, addressComponents }) =>
      setIntakeProfile({ ...intakeProfile, address, addressComponents })
    );
  };

  const handleClickAddressPrediction = async (
    prediction: google.maps.places.AutocompletePrediction
  ): Promise<{
    address: string | null;
    addressComponents: Messages["IntakePatientProfile"]["addressComponents"] | null;
  }> => {
    setShowPredictions(false);
    let address: string | null = null;
    let addressComponents: Messages["IntakePatientProfile"]["addressComponents"] | null = null;

    try {
      const result = await getAddressDetails(prediction);
      const placeId = result?.place_id;

      if (placeId === undefined) {
        return {
          address: null,
          addressComponents: null,
        };
      }

      const addressDetails = await fromPlaceId(placeId, {
        apiKey: GOOGLE_MAPS_API_KEY,
        mfAutoFix: true,
      });
      addressComponents = {
        components: toMfAddressComponents(addressDetails),
        text: addressDetails.fullAddress,
        timezone: "America/New_York",
      };
      address = prediction.description;
      return { address, addressComponents };
    } catch (error) {
      console.error("Error getting address details", error);
      return { address: null, addressComponents: null };
    }
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeAddress(event);
    setIntakeProfile({ ...intakeProfile, address: event.target.value, addressComponents: null });
  };

  return (
    <Flex direction="column" gap={4} marginBottom="50px" marginTop="100px">
      <Box
        position="fixed"
        zIndex={999}
        backgroundColor="white"
        height="90px"
        width="728px"
        top="0"
        borderBottom="1px"
        borderColor="lightgray"
      >
        <IntakeProfileHeader
          isEditMode={isEditMode}
          patientId={intakeProfile.id}
          gender={intakeProfile.gender}
          firstName={intakeProfile.firstName}
          lastName={intakeProfile.lastName}
          displayId={intakeProfile.displayId}
          nextCallDate={intakeProfile.nextCallDate}
          onClickEdit={handleEditModeChange}
          onClickPatientLink={props.onClickPatientLink}
        />
      </Box>

      <IntakeProfilePersonalInfoSection
        isEditMode={isEditMode}
        places={placePredictions}
        showPredictions={showPredictions}
        intakeStatuses={props.intakeStatuses}
        patientInfo={{
          address: intakeProfile.address,
          address2: null,
          addressInstructions: null,
          addressComponents: intakeProfile.addressComponents,
          createdAt: intakeProfile.createdAt,
          dateOfBirth: intakeProfile.dateOfBirth,
          eligibilityFlow: intakeProfile.eligibilityFlow,
          gender: intakeProfile.gender,
          intakeStatus: intakeProfile.intakeStatus,
          lastCallDate: intakeProfile.lastCallDate,
          nextCallDate: intakeProfile.nextCallDate,
        }}
        onChangePatientInfoField={(field, value) =>
          setIntakeProfile({ ...intakeProfile, [field]: value })
        }
        onChangeAddress={handleAddressChange}
        onClickAddress={handleClickAddress}
      />

      <Divider variant="solid"></Divider>
      <IntakeProfileContactInfoSection
        isEditMode={isEditMode}
        patientPhoneNumber={intakeProfile.phoneNumbers[0].phonenumber}
        emergencyContactInfo={
          intakeProfile.familyInformation === null
            ? {
                firstName: "",
                lastName: "",
                relationship: "",
                mobilePhoneNumber: "",
                homePhoneNumber: "",
              }
            : intakeProfile.familyInformation[0]
        }
        onChangeEmergencyContact={(field, value) => handleEmergencyContactChange(field, value)}
        onChangePatientPhoneNumber={(value) =>
          setIntakeProfile({
            ...intakeProfile,
            phoneNumbers: [
              { ...intakeProfile.phoneNumbers[0], phonenumber: value },
              ...intakeProfile.phoneNumbers,
            ],
          })
        }
      />

      <Divider variant="solid"></Divider>
      <IntakeProfileMedInfoSection
        isEditMode={isEditMode}
        patientId={intakeProfile.id}
        medicaidId={intakeProfile.medicaidId}
        medicareId={intakeProfile.medicareId}
        medicaidStatus={intakeProfile.medicaidStatus}
        eligibilityCheckResults={props.eligibilityCheckResults}
        onChangeIds={(field, value) => setIntakeProfile({ ...intakeProfile, [field]: value })}
        onClickCheckEligibility={props.onClickEligibilityCheck}
      />

      <Divider variant="solid"></Divider>
      <IntakePatientProfileNotesSection
        notes={intakeProfile.notes}
        onClickNewNote={() => props.onClickAddNewNote(props.patientInfo.id)}
        onClickMarkAsDone={props.onClickMarkNoteAsDone}
      />

      <Divider variant="solid"></Divider>
      <IntakeProfilePlanInfo
        isEditMode={isEditMode}
        currPlanName={intakeProfile.plan?.name ?? "Unknown"}
        plans={props.intakePlans}
        planStartDate={intakeProfile.planStartDate}
        planEnrollmentStatus={intakeProfile.planEnrollmentStatus}
        UASStatus={intakeProfile.UASCompleted}
        UASDate={intakeProfile.UASAssessmentDate}
        UASHoursAmount={intakeProfile.UASHoursAmount}
        onChangePlan={(plan) => setIntakeProfile({ ...intakeProfile, plan: plan })}
        onChangeField={(field, value) => setIntakeProfile({ ...intakeProfile, [field]: value })}
      />

      <Divider variant="solid"></Divider>
      <IntakeProfileNYIAInfo
        isEditMode={isEditMode}
        NYIA1Date={intakeProfile.NYIA1AppoitmentDate}
        NYIA1Status={intakeProfile.NYIA1Completed}
        NYIA2Date={intakeProfile.NYIA2AppoitmentDate}
        NYIA2Status={intakeProfile.NYIA2Completed}
        NYIAResults={intakeProfile.NYIAResults}
        onChangeField={(field, value) => setIntakeProfile({ ...intakeProfile, [field]: value })}
      />
    </Flex>
  );
};

export default IntakePatientProfile;

const IntakeProfileHeader = (props: {
  isEditMode: boolean;
  patientId: PatientId;
  firstName: string;
  lastName: string;
  gender: "M" | "F" | null;
  displayId: number | null;
  nextCallDate: LocalDateTime | null;
  onClickPatientLink: () => void;
  onClickEdit: (value: boolean) => void;
}) => {
  const getNextCallStyleProps = () => {
    if (props.nextCallDate === null) {
      return {
        text: "No call was scheduled",
        badgeColor: "gray",
      };
    } else {
      if (props.nextCallDate.isBefore(LocalDateTime.now())) {
        return {
          text: "Due date has passed",
          badgeColor: "red",
        };
      } else {
        const nextCallFromNowDays = LocalDateTime.now().until(props.nextCallDate, ChronoUnit.DAYS);
        const daysText = nextCallFromNowDays === 1 ? "day" : "days";
        const text =
          nextCallFromNowDays === 0 ? `Due Today` : `Due in ${nextCallFromNowDays} ${daysText}`;

        return {
          text,
          badgeColor: nextCallFromNowDays === 0 ? "yellow" : "whatsapp",
        };
      }
    }
  };

  const patientFullName = `${props.firstName} ${props.lastName} ${
    props.displayId !== null ? `(${props.displayId})` : ""
  }`;

  const nextCallBadgeProps = getNextCallStyleProps();

  return (
    <>
      <Flex direction="row" justifyContent="space-between" margin="15px">
        <Flex direction="row" gap={3}>
          <EntityCardLink
            boxProps={{ fontSize: "xl", marginBottom: "5px", p: 0 }}
            entity={{
              type: "Patient",
              id: props.patientId,
              displayId: props.displayId,
              gender: props.gender,
              fullName: patientFullName,
              status: "DRAFT",
            }}
            onClick={props.onClickPatientLink}
          />
          <Button
            marginTop="3px"
            padding="10px"
            height="25px"
            fontSize="12px"
            colorScheme={nextCallBadgeProps.badgeColor}
            boxShadow="none"
            cursor="default"
            _hover={{ colorScheme: nextCallBadgeProps.badgeColor }}
            _active={{ colorScheme: nextCallBadgeProps.badgeColor }}
            leftIcon={<PhoneIcon />}
            onClick={(event) => event.preventDefault()}
          >
            {nextCallBadgeProps.text}
          </Button>
        </Flex>
        <Box marginRight="25px">
          {props.isEditMode ? (
            <Button
              leftIcon={<SaveIcon />}
              colorScheme="blue"
              fontSize="12px"
              onClick={() => props.onClickEdit(false)}
            >
              Save
            </Button>
          ) : (
            <Button
              leftIcon={props.isEditMode ? <SaveIcon /> : <EditIcon />}
              variant="ghost"
              color="blue.500"
              fontSize="12px"
              onClick={() => props.onClickEdit(true)}
            >
              Edit
            </Button>
          )}
        </Box>
      </Flex>
    </>
  );
};
