import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { match } from "ts-pattern";
import { Messages } from "../../../../core/api";
import { Entity, EntityWithStatus } from "../../../../shared/components/EntityCard";
import Link from "../../../../shared/components/Link";
import Page from "../../../../shared/components/Page";
import {
  CommCenterLabelId,
  CommCenterTeamId,
  CommCenterTicketId,
  NoteSubjectId,
} from "../../../../shared/schema/schema";
import { Loadable } from "../../../../shared/utils/loadable";
import TicketsBox from "../../components/TicketsBox";
import { NewTicketRequestBody } from "../../utils/communication-utils";

type Props = {
  ticket: Messages["CommCenterTicket"] | null;
  teams: Messages["CommCenterTeamWithMembers"][];
  entityToDisplay: Loadable<EntityWithStatus<Entity>>;
  relatedTickets: Loadable<Messages["CommCenterTicket"][]>;
  labels: Messages["CommCenterLabel"][];
  initialLabelId: CommCenterLabelId | null;
  noteSubjects: { label: string; value: NoteSubjectId }[];
  liveCallTicketIds: CommCenterTicketId[];
  attachments: File[];
  onEditTicket: (
    ticketId: CommCenterTicketId,
    editFields: Messages["Partial<EditCommCenterTicketParams>"]
  ) => void;
  onClickTicket: (ticketId: CommCenterTicketId) => void;
  onClickMarkAsUnread: (ticketId: CommCenterTicketId) => void;
  onSubmitNewMessage: (ticketId: CommCenterTicketId, message: string) => void;
  onCreateNewTicket: (newTicket: NewTicketRequestBody) => void;
  onSelectFile: (newFile: File) => void;
  onClickRemoveAttachment: (attachment: File) => void;
};

const CommunicationCenterTicketPage = (props: Props) => {
  const newTicketDisclosure = useDisclosure();

  const onboardingStageName = match(props.entityToDisplay)
    .with({ type: "Loading" }, () => null)
    .with({ type: "Resolved" }, ({ value }) => getOnboardingNameIfExist(props.ticket, value))
    .with({ type: "Rejected" }, () => null)
    .run();

  const settings = {
    // TODO: Make the settings nullable in tickets box. on null it will present default values or blanks.
    assignedToId: props.ticket?.assignedTo?.id ?? null,
    status: props.ticket?.status ?? "NEW",
    teamId: props.ticket?.relatedTeam.id ?? CommCenterTeamId.wrap(0),
  };

  const areActionsDisabled = props.ticket?.status === "RESOLVED" || newTicketDisclosure.isOpen;

  return (
    <Page>
      <Page.Header>
        <Flex gap={4} alignItems="center">
          <Button leftIcon={<ChevronLeftIcon />} as={Link} variant="outline" to="app.commcenter">
            Go back
          </Button>
        </Flex>
      </Page.Header>
      <Box sx={{ "--max-chat-height": "calc(100vh - 333px)" }}>
        <TicketsBox
          liveCallTicketIds={props.liveCallTicketIds}
          noteSubjects={props.noteSubjects}
          teams={props.teams}
          label={props.ticket?.label?.name ?? null}
          primaryEntity={props.entityToDisplay}
          settings={settings}
          activeTicket={props.ticket ?? null}
          tickets={props.relatedTickets}
          onboardingStageName={onboardingStageName}
          labels={props.labels}
          attachments={props.attachments}
          initialLabelId={props.initialLabelId}
          isNewTicketOpen={newTicketDisclosure.isOpen}
          areActionsDisabled={areActionsDisabled}
          onCreateNewTicket={props.onCreateNewTicket}
          onChangeSettings={props.onEditTicket}
          onClickMarkAsUnread={props.onClickMarkAsUnread}
          onClickTicket={props.onClickTicket}
          onSubmitNewMessage={props.onSubmitNewMessage}
          onClickNewTicket={newTicketDisclosure.onOpen}
          onRequestCloseNewTicket={newTicketDisclosure.onClose}
          onSelectFile={props.onSelectFile}
          onClickRemoveAttachment={props.onClickRemoveAttachment}
        />
      </Box>
    </Page>
  );
};

function getOnboardingNameIfExist(
  ticket: Messages["CommCenterTicket"] | null,
  entityToDisplay: EntityWithStatus<Entity>
): string | null {
  if (ticket !== null) {
    return ticket.relatedCaregiver?.onboardingStageDetails?.name ?? null;
  } else {
    if (entityToDisplay.type === "Caregiver") {
      return entityToDisplay.status; // TODO: Add onboarding stage to the entity object.
    }
  }

  return null;
}

export default CommunicationCenterTicketPage;
