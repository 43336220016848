import { useDisclosure, useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useRouter } from "@uirouter/react";
import React from "react";
import { Messages, QueryParamsOf } from "../../../../core/api";
import LoadingPage from "../../../../shared/components/LoadingPage";
import useApi from "../../../../shared/hooks/useApi";
import useLiveCallTicketIds from "../../../../shared/hooks/useLiveCallTicketIds";
import usePhoneNumberDialer from "../../../../shared/hooks/usePhoneNumberDialer";
import useAuthData from "../../../../shared/hooks/useAuthInfo";
import { useQueryParams } from "../../../../shared/hooks/useQueryParams";
import useSocketEvent from "../../../../shared/hooks/useSocketEvent";
import { queryKeys } from "../../../../shared/query-keys";
import { AgencyMemberId, CommCenterTeamMemberId } from "../../../../shared/schema/schema";
import { formatErrorResponse } from "../../../../shared/utils/format-response-error";
import {
  getCommCenterTeamMemberIdByAgencyMemberId,
  NewTicketRequestBody,
} from "../../utils/communication-utils";
import OpenCommunicationsPage from "./OpenCommunicationsPage";
import useMultipleSocketEvent from "../../../../shared/hooks/useMultipleSocketEvent";
import useLocalFilters from "../../../../shared/hooks/useLocalFilters";

export type CommCenterLocalFilters = {
  caregiver: string;
  awaitingResponse: "Caregiver" | "Agency" | "All";
};

const OpenCommunicationsRoute = () => {
  const { api } = useApi();
  const { agencyMember } = useAuthData();
  const toast = useToast();
  const { stateService } = useRouter();
  const dialDisclosure = useDisclosure();
  const { dialNumber, isCalling } = usePhoneNumberDialer(dialDisclosure.onClose);

  const queryParams = useQueryParams<QueryParamsOf<"get", "./comm_center/tickets">>({
    storageKey: ["comm_center", "tickets-table"],
    defaultOptions: {
      ticketStatus: ["IN PROGRESS", "NEW"],
    },
  });

  const localFiltersParams = useLocalFilters<Messages["CommCenterTicket"], CommCenterLocalFilters>({
    caregiver: {
      filter: (ticket, currentFilter) =>
        ticket.relatedCaregiver?.name.toLowerCase().includes(currentFilter) === true ||
        `${ticket.relatedCaregiver?.displayId}`.includes(currentFilter) === true,
    },
    awaitingResponse: {
      filter: (ticket, currentFilter) =>
        (currentFilter === "Agency" && ticket.messages.at(-1)?.createdBy.type === "Caregiver") ||
        (currentFilter === "Caregiver" &&
          ["Agency Member", "System"].includes(ticket.messages.at(-1)?.createdBy.type ?? "")) ||
        currentFilter === "All",
    },
  });

  const [filterList] = localFiltersParams;

  const tickets = useQuery({
    queryKey: queryKeys.commCenter.search(queryParams.params),
    keepPreviousData: true,
    queryFn: () => {
      return api.get("./comm_center/tickets", {
        query: queryParams.params.toJSON(),
      });
    },
  });

  const stats = useQuery({
    queryKey: queryKeys.commCenter.stats(),
    queryFn: () => api.get("./comm_center/tickets/stats", {}),
  });

  const teams = useQuery({
    queryKey: queryKeys.commCenter.teams(),
    queryFn: () => api.get("./comm_center/teams", {}),
  });

  const stages = useQuery({
    queryKey: ["onboarding_stages"],
    queryFn: () => api.get("./onboarding_stages", {}),
  });

  const labels = useQuery({
    queryKey: queryKeys.commCenter.labels(),
    queryFn: () => api.get("./comm_center/labels", {}),
    keepPreviousData: true,
    select: (response) => response.labels,
  });

  const callCenterLiveCalls = useLiveCallTicketIds();

  const ticketSources = useQuery({
    queryKey: ["ticket_sources"],
    queryFn: () => api.get("./comm_center/tickets/sources", {}),
    keepPreviousData: true,
    select: (response) => response.sources,
  });

  useSocketEvent({
    key: "CommCenterTicketUpdated",
    onEvent: ({ ticketId }) => {
      if (tickets.data?.tickets.some((ticket) => ticket.id === ticketId)) {
        tickets.refetch();
      }
    },
  });

  useMultipleSocketEvent({
    keys: [
      "CallCenterCallEnd",
      "CallCenterCallStart",
      "CallCenterInboundCall",
      "CallCenterCallerHangupBeforeCallStart",
      "CallCenterOutboundCall",
    ],
    onEvent: () => {
      tickets.refetch();
    },
  });

  const createTicket = useMutation({
    mutationFn: (newTicketRequest: NewTicketRequestBody) => {
      return api.post(
        "/agencies/:agencyId/comm_center_team_members/:commCenterTeamMemberId/comm_center/tickets",
        {
          path: {
            commCenterTeamMemberId:
              getCommCenterTeamMemberIdByAgencyMemberId(teams.data?.teams ?? [], agencyMember.id) ??
              CommCenterTeamMemberId.wrap(0),
          },
          body: newTicketRequest,
        }
      );
    },
    onSuccess: (response) => {
      stateService.go("app.commcenter_ticket", { ticketId: response.ticketId });
    },
    onError: (error) => {
      toast({
        title: "Could not create new ticket",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const handleCreateTicket = (newTicketDetails: NewTicketRequestBody) => {
    createTicket.mutate(newTicketDetails);
  };

  const handleClickAgencyMember = (agencyMemberId: AgencyMemberId) => {
    console.log(`Call agency member id: ${agencyMemberId}`);
  };

  if (
    tickets.isLoading ||
    stats.isLoading ||
    teams.isLoading ||
    stages.isLoading ||
    labels.isLoading ||
    ticketSources.isLoading ||
    callCenterLiveCalls.isLoading
  ) {
    return <LoadingPage />;
  }

  if (
    tickets.isError ||
    stats.isError ||
    teams.isError ||
    stages.isError ||
    labels.isError ||
    ticketSources.isError ||
    callCenterLiveCalls.isError
  ) {
    return <div>Error</div>;
  }

  if (
    !tickets.data ||
    !stats.data ||
    !teams.data ||
    !stages.data ||
    !labels.data ||
    !ticketSources.data ||
    !callCenterLiveCalls.data
  ) {
    return <div>Something went wrong</div>;
  }

  return (
    <OpenCommunicationsPage
      localFiltersParams={localFiltersParams}
      tickets={filterList(tickets.data.tickets)}
      liveCallTicketIds={callCenterLiveCalls.data}
      ticketSources={ticketSources.data}
      teams={teams.data.teams}
      stages={stages.data.stages}
      labels={labels.data.filter((label) => label.active)}
      initialLabelId={
        labels.data?.find((label) => label.parent === null && !label.active)?.id ?? null
      }
      stats={stats.data}
      filters={queryParams}
      isRefetching={tickets.isFetching}
      isCalling={isCalling}
      dialDisclosure={dialDisclosure}
      onChangeFilter={queryParams.setValue}
      onChangeFilters={queryParams.setValues}
      onCreateNewTicket={handleCreateTicket}
      onClickDial={dialNumber}
      onClickAgencyMember={handleClickAgencyMember}
    />
  );
};

export default OpenCommunicationsRoute;
